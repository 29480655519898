.resume {
    /* margin-bottom: 200px; */
    width: 100%;
    height: 2000px;
}

@media screen and (max-width: 640px) {
  
    .resume {
        width: 330px;
        height: auto;
    }

  }