#contact-icons {
    margin-left: 15px;
    margin-right: 15px;
    color: #546e7a;
}

label {
    font-size: 18px;
}

@media screen and (max-width: 640px) {
  
    #contact-icons {
        margin-left: 1px;
        margin-right: 1px;
    }
  }