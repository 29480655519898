.skyline-photo {
    /* width: 100%; */
    margin-top: 10px;
    /* height: 200px; */
    /* margin-bottom: -100px; */
}

#intro {
    /* color: white; */
    font-size: 25px;
}