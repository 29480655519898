/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.navbar-fixed {
    z-index: 1;
}


.page-footer {
    margin-top: 100px;
}

#footer-icons {
    color: white;
    margin-left: 6px;
    margin-right: 6px;
}
.home-nav-item {
    text-decoration: none;
    color: #546e7a;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
} 

.home-nav-item:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.home-nav-icon {
    text-decoration: none;
    color: #546e7a;
} 
.skyline-photo {
    /* width: 100%; */
    margin-top: 10px;
    /* height: 200px; */
    /* margin-bottom: -100px; */
}

#intro {
    /* color: white; */
    font-size: 25px;
}
#profile-pic {
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 10px;
}
#portfolio {
    height: 100%;
    font-size: 28px;
    margin-bottom: 50px;
}

.portfolio-item {
    margin-top: 30px;
    font-size: 28px;
}

.image-background {
    position: relative;
    height: 180px;
    width: 280px;
    background-color: black;
} 

.nav-images {
  opacity: 1;
  height: 180px;
  width: 280px;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.portfolio-item:hover .nav-images {
  opacity: 0.3;
}

.portfolio-item:hover .middle {
  opacity: 1;
}

.middle-text {
  color: white;
  font-size: 17px;
  width: 200px;
}

#portfolio-git-icon {
  text-decoration: none;
  color: #546e7a;
}
.resume {
    /* margin-bottom: 200px; */
    width: 100%;
    height: 2000px;
}

@media screen and (max-width: 640px) {
  
    .resume {
        width: 330px;
        height: auto;
    }

  }
#contact-icons {
    margin-left: 15px;
    margin-right: 15px;
    color: #546e7a;
}

label {
    font-size: 18px;
}

@media screen and (max-width: 640px) {
  
    #contact-icons {
        margin-left: 1px;
        margin-right: 1px;
    }
  }
body {
    font-family: 'Inconsolata', monospace;
  }
