#portfolio {
    height: 100%;
    font-size: 28px;
    margin-bottom: 50px;
}

.portfolio-item {
    margin-top: 30px;
    font-size: 28px;
}

.image-background {
    position: relative;
    height: 180px;
    width: 280px;
    background-color: black;
} 

.nav-images {
  opacity: 1;
  height: 180px;
  width: 280px;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.portfolio-item:hover .nav-images {
  opacity: 0.3;
}

.portfolio-item:hover .middle {
  opacity: 1;
}

.middle-text {
  color: white;
  font-size: 17px;
  width: 200px;
}

#portfolio-git-icon {
  text-decoration: none;
  color: #546e7a;
}